




































import { UserModule } from '@/store/modules/user'
import { Component, Vue } from 'vue-property-decorator'
@Component
export default class extends Vue {
  form = {
    idCard: '',
  }
  loading = false

  login() {
    if (this.form.idCard.length == 0) {
      this.$message.error('请输入身份证号')
      return
    }
    this.loading = true
    // login({ idCard: this.idCard })
    UserModule.Login(this.form)
      .then(() => {
        this.$router.push({ path: '/' }).catch((e) => console.log('登录跳转失败', e))
      })
      .catch((e: any) => {
        const error = (e.response && e.response && e.response.data.message) || ''
        this.$message.error('登录失败: ' + error)
      })
      .finally(() => (this.loading = false))
  }

  input(s: string) {
    this.form.idCard += s
  }

  back() {
    if (this.form.idCard.length > 0) {
      this.form.idCard = this.form.idCard.substring(0, this.form.idCard.length - 1)
    }
  }
}
